import React, {useState} from 'react';
import './App.css';

const quotes = require('./static/quotes.json');

function getRandomId() {
    return Math.floor(Math.random() * 15);
}

function App() {
    const id = getRandomId();

  return (
    <div className="App">
      <header className="App-header">
        <div className="App-Photo">
            <img src={require(`./static/images/${id}.png`)} className="App-logo" alt="logo" />
        </div>

        <p className={"App-Photo-Sub"}>
            {quotes.quotes[id]}
        </p>
      </header>
    </div>
  );
}

export default App;
